import React from 'react';

import { PressEvent } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { Sizes } from '../../../components/types';
import Button, { ButtonTypes } from '../buttons/Button';

export type ActionProps = {
    className?: string;
    label: string;
    destructable?: boolean;
    event?(e: PressEvent): void;
};

const Action = (props: ActionProps): React.ReactElement => {
    const { className, label, destructable = false, event } = props;
    
    return <div className='mb-1'>
        <Button
            onPress={event}
            className={twMerge('px-1 py-0', destructable && 'text-text-text-destructable')}
            buttonType={ButtonTypes.Ghost} size={Sizes.Small}>
            {label}
        </Button>
    </div>
}
export default Action;
