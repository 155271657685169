import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ItemSelector from '../../../../base/ui/components/listitems/ItemSelector';
import { DetailedDocumentAppliesToProduct } from '../../../../compliance_api/models';
import { useAppDispatch } from '../../../../store';
import { complianceDocumentGetSuggestedProducts } from '../../complianceSlice';
import { getComplianceDocumentSuggestedProductsSelector } from '../../selectors/ComplianceSelectors';
import ComplianceTemporaryItemNumber from '../ComplianceTemporaryItemNumber';

type Props = {
    className?: string;
    documentId: string;
    onItemSelected?(ids: string[]): void
    currentProducts: DetailedDocumentAppliesToProduct[];
};

const ComplianceDocumentSuggestProducts = (props: Props): React.ReactElement => {
    const { className, documentId, onItemSelected, currentProducts } = props;
    const dispatch = useAppDispatch();
    const suggestedProducts = useSelector(getComplianceDocumentSuggestedProductsSelector);
    const getOptions = () => {
        let r = [];
        suggestedProducts?.map((sp) => {
            if (!selected?.includes(sp.product_id)) {
                r.push({
                    id: sp.product_id,
                    value: sp.product_id,
                    text: sp.name,
                    content: <span className='space-x-1 flex'><span>{sp.name}</span><span className='text-secondary'>{sp.item_number || <ComplianceTemporaryItemNumber temporaryItemNumber={sp.temporary_item_number} />}</span></span>
                })
            }
        })
        return r;
    }
    const [options, setOptions] = useState(getOptions());
    const [selected, setSelected] = useState(currentProducts.map((cp) => cp.product_id));
    const intl = useIntl();

    useEffect(() => {
        dispatch(complianceDocumentGetSuggestedProducts(documentId));
    }, []);

    useEffect(() => {
        setOptions(getOptions());
    }, [suggestedProducts])

    useEffect(() => {
        setOptions([...options.filter(opt => !selected.includes(opt.id))])
    }, [selected])

    return <ItemSelector
        show={true}
        multiple
        className=''
        placeholderText={intl.formatMessage({ id: 'compliance.covered_products.select_products' })}
        items={options}
        onItemSelected={(items) => {
            setSelected(items);
            onItemSelected(items);
        }} />

}
export default ComplianceDocumentSuggestProducts;
1