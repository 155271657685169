import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from '../../../base/hooks';
import { Search } from '../../../base/ui/components/fields/Field';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';

type Props = {
    className?: string;
};
enum SearchContextEnum {
    products = 'products',
    inspections = 'inspections'
}
type SearchContext = Record<Required<SearchContextEnum>, { url: string, placeholderText: string, query: string }>

const searchContext: SearchContext = {
    'products': {
        url: '/compliance/products',
        query: 'q',
        placeholderText: 'compliance.search.search_products',
    },
    'inspections': {
        url: '/inspections',
        query: 'search',
        placeholderText: 'search.search_inspections',
    }
}

const GlobalSearch = (props: Props): React.ReactElement => {
    const { className } = props;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const isCompliance = location.pathname.includes('compliance');
    const context = isCompliance ? searchContext.products : searchContext.inspections;

    const search = (q: string): void => {
        history.push(context.url + '?' + context.query + '=' + q);
    }
    return <div className={twMerge('', className)}>
        <Search
            autoFocus
            className={twMerge('w-72', className)}
            onSubmit={(q: string) => search(q)}
            placeholder={useTranslation(context.placeholderText)}
            data-test-id='input-search'
        />
    </div>
}
export default GlobalSearch;
