import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox } from '../../../base/components/basic/CheckboxRadio';
import { useTranslation } from '../../../base/hooks';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import { Field } from '../../../base/ui/components/fields/Field';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { complianceCreateProductErrorSelector } from '../selectors/ComplianceSelectors';
import { HandleProductUpdateType } from './ComplianceProductDetail';
import ComplianceTemporaryItemNumberMarker from './ComplianceTemporaryItemNumberMarker';

type Props = {
    className?: string;
    productName?: string;
    productNumber?: string;
    handleProductUpdate(props: HandleProductUpdateType): void;
    save(): void;
    cancelCreate(): void;
};

const ComplianceCreateProductModal = (props: Props): React.ReactElement => {
    const { className, handleProductUpdate, cancelCreate } = props;
    const intl = useIntl();
    const [productNumber, setProductNumber] = useState(props.productNumber);
    const [productName, setProductName] = useState(props.productName);
    const createProductError = useSelector(complianceCreateProductErrorSelector);
    const createProductErrorText = createProductError ? useTranslation(createProductError) : null;
    const [isTemporaryItemNumber, setIsTemporaryItemNumber] = useState(false);
    const [errorMessage, setErrorMessage] = useState({ number: null, name: null });
    const checkValidation = (type?: 'number' | 'name') => {
        const t = !type ? 'all' : type;
        const numberError = intl.formatMessage({ id: 'compliance.product_create.error.please_set_product_number' });
        const nameError = intl.formatMessage({ id: 'compliance.product_create.error.please_set_product_name' });
        const numberValid = !isUndefinedOrNullOrEmptyString(productNumber);
        const nameValid = !isUndefinedOrNullOrEmptyString(productName);
        if (t === 'number') {
            setErrorMessage({
                ...errorMessage,
                number: numberValid ? null : numberError,
            })
            return numberValid;
        }
        if (t === 'name') {
            setErrorMessage({
                ...errorMessage,
                name: nameValid ? null : nameError,
            })
            return nameValid;
        }
        setErrorMessage({
            ...errorMessage,
            name: nameValid ? null : nameError,
            number: numberValid ? null : numberError,
        })
        return numberValid && nameValid;
    }
    const save = () => {
        if (checkValidation()) {
            props.save();
            setErrorMessage({ number: null, name: null });
        }
    }
    useEffect(() => {
        handleProductUpdate([
            { name: 'product_temporary_item_number', value: isTemporaryItemNumber ? productNumber : null },
            { name: 'product_number', value: isTemporaryItemNumber ? null : productNumber },
        ]);
    }, [isTemporaryItemNumber])
    const createModal = <Modal
        onOpenChange={(c) => !c && cancelCreate()}
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: intl.formatMessage({ id: 'compliance.product_create.label.create_product' }), stayOpen: true, event: save, /* disabled: isUndefinedOrNullOrEmptyString(productName) || isUndefinedOrNullOrEmptyString(productNumber) */ },
            { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'globals.cancel' }), event: cancelCreate },
        ]}
        isOpen={true}
        width={500}
        header='New product'
    >
        <div className='flex flex-col gap-y-4'>
            <div className='space-y-2'>
                {!isTemporaryItemNumber && <Field
                    label={{ text: intl.formatMessage({ id: 'compliance.product_create.product_number' }) }}
                    value={productNumber}
                    onChange={setProductNumber}
                    onBlur={() => {
                        handleProductUpdate([
                            { name: 'product_temporary_item_number', value: null },
                            { name: 'product_number', value: productNumber },
                        ]);
                        checkValidation('number');
                    }}
                    errorMessage={errorMessage.number || createProductErrorText}
                />}
                {isTemporaryItemNumber && <Field
                    value={productNumber}
                    onChange={setProductNumber}
                    label={{ text: 'Temporary item number ' }}
                    labelLeft={<ComplianceTemporaryItemNumberMarker className='text-base' />}
                    onBlur={() => {
                        handleProductUpdate([
                            { name: 'product_temporary_item_number', value: productNumber },
                            { name: 'product_number', value: null },
                        ]);
                        checkValidation('number');
                    }}
                    errorMessage={errorMessage.number || createProductErrorText}
                />}
                <Checkbox checked={isTemporaryItemNumber} label={'Set as temporary item number'} onChange={() => {
                    setIsTemporaryItemNumber(!isTemporaryItemNumber);
                }} />
            </div>
            <div>
                <Field
                    value={productName}
                    label={{ text: intl.formatMessage({ id: 'compliance.product_create.product_name' }) }}
                    onChange={setProductName}
                    onBlur={() => {
                        handleProductUpdate([
                            { name: 'product_name', value: productName },
                        ]);
                        checkValidation('name');
                    }}
                    errorMessage={errorMessage.name}
                />
            </div>
        </div>
    </Modal >

    return createModal;
}
export default ComplianceCreateProductModal;
