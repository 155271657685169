import React from 'react';
import { twMerge } from '../../../base/utils';

type Props = {
    className?: string;
};

const ComplianceTemporaryItemNumberMarker = (props: Props): React.ReactElement => {
    const { className } = props;
    return <span className={twMerge('font-bold text-secondary self-start', className)}>T/</span>;
}
export default ComplianceTemporaryItemNumberMarker;
