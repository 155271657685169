import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { Sizes } from '../../../base/components/types';
import Button from '../../../base/ui/components/buttons/Button';
import ChipStack from '../../../base/ui/components/chips/ChipStack';
import KeyValuePair from '../../../base/ui/components/keyValuePair/KeyValuePair';
import ComponentsSeparatedBy from '../../../base/ui/components/util/ComponentsSeparatedBy';
import { isUndefinedOrNull, twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import UserPopup from '../../users/components/UserPopup';
import UsersSelector from '../../users/components/UsersSelector';
import { getUserNameText } from '../../users/components/Widgets';
import { getGroups, getGroupsById, getUsers, getUsersById } from '../../users/selectors';
import { complianceSetProductResponsibleGroups, complianceSetProductResponsibleUsers } from '../complianceSlice';

type Props = {
    productId: string;
    name?: string;
    className?: string;
    responsibleUsers?: string[];
    responsibleGroups?: string[];
    onUsersUpdated?(userIds: string[]): void;
    onGroupsUpdated?(groupIds: string[]): void;
    edit: boolean;
};

const ComplianceResponsiblesSelector = (props: Props): React.ReactElement => {
    const { className, responsibleUsers, responsibleGroups, productId, onUsersUpdated, onGroupsUpdated, name } = props;
    const responsiblesById = useSelector(getUsersById);
    const users = useSelector(getUsers);
    const groups = useSelector(getGroups);
    const groupsById = useSelector(getGroupsById);
    const [selectedUsers, setSelectedUsers] = useState(responsibleUsers || []);
    const [selectedGroups, setSelectedGroups] = useState(responsibleGroups || []);
    const setupGroups = () => {
        const gt = groups.filter((g) => !selectedGroups?.includes(g.id));
        return gt.map((g) => ({ key: g.id, id: g.id, value: g.id, text: g.name }))
    }
    const [groupsOptions, setGroupOptions] = useState(setupGroups());
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [edit, setEdit] = useState(props.edit);
    const [showUsersList, setShowUsersList] = useState(false);
    const [showGroupsSelector, setShowGroupsSelector] = useState(false);
    const items = [
        {
            name: intl.formatMessage({ id: 'corrective_actions.responsible.groups' }),
            type: 'groups',
            icon: 'user_group',
            items: groups.map((u) => ({ id: u.id, name: u.name, icon: 'user_group', })),
        },
        {
            name: intl.formatMessage({ id: 'corrective_actions.responsible.users' }),
            type: 'users',
            items: users.map((u) => ({ id: u.id, name: getUserNameText(u) })),
        },

    ];
    const getLabel = () => {
        const andLabel = intl.formatMessage({ id: 'globals.and_lower_case' });
        const noResponsibles = responsibleGroups.length === 0 && responsibleUsers?.length === 0;
        if (noResponsibles) {
            return intl.formatMessage({ id: 'compliance.product_detail.responsible_users.no_one_assigned' });
        }
        let users;
        let groups;
        const usersA = responsibleUsers?.map((id) => <UserPopup className='cursor-default' user={responsiblesById[id] && (responsiblesById[id])} />);
        const groupsA = responsibleGroups.map((id) => groupsById[id]?.id);
        users = <ComponentsSeparatedBy components={[...usersA, ...groupsA]} separateBy=',' endWithLabel={andLabel} />
        return <div className='flex flex-wrap'>{users}</div>;
    }

    const getClipLabel = (itemId: any) => {
        let _item;
        items.forEach((g) => {
            g.items.forEach((item) => {
                if (item.id === itemId) {
                    _item = item;
                }
            });
        })
        return _item;
    }

    const saveUsers = (ids: string[]) => {

        onUsersUpdated ? onUsersUpdated(ids) : dispatch(complianceSetProductResponsibleUsers({ product_id: productId, user_ids: ids }));
    }

    const saveGroups = (ids: string[]) => {
        onGroupsUpdated ? onGroupsUpdated(ids) : dispatch(complianceSetProductResponsibleGroups({ product_id: productId, group_ids: ids }));
    }

    useEffect(() => {
        setEdit(props.edit);
    }, [props.edit]);

    useEffect(() => {
        setSelectedUsers(responsibleUsers);
    }, [responsibleUsers]);

    useEffect(() => {
        if (!isUndefinedOrNull(responsibleGroups)) {
            setSelectedGroups(responsibleGroups);
        }
    }, [responsibleGroups]);

    useEffect(() => {
        setGroupOptions(setupGroups());
    }, [selectedGroups]);

    const content = <div className={twMerge('gap-y-2', className)}> {edit ?
        <div className='flex flex-col gap-2'>
            <ChipStack
                noValueLabel={intl.formatMessage({ id: 'compliance.product_detail.responsible_users.select_user' })}
                // showAdd={(selectedUsers?.length < users?.length)}
                showAdd={true}
                onAdd={() => {
                    setShowUsersList(true);
                    setShowGroupsSelector(false);
                }}
                onRemoveClip={(index) => {
                    const su = [...selectedUsers];
                    su.splice(index, 1);
                    setSelectedUsers(su);
                    saveUsers(su);
                }}
                size={Sizes.Small}
                clips={selectedUsers?.map((id: any ) => {
                    const clipItem = getClipLabel(id);
                    return { label: clipItem?.name, icon: clipItem?.icon, removable: true }
                })} />
            {showUsersList &&
                <div className='flex w-full gap-2 pb-8'>
                    <UsersSelector
                        fluid
                        hideSelected={true}
                        name='approver'
                        users={{ ...responsiblesById }}
                        placeholder={intl.formatMessage({ id: 'filters.responsibles.placeholder' })}
                        allowMultipleSelection={true}
                        value={selectedUsers}
                        showEmailInSelector
                        handleChange={(selected) => {
                            setSelectedUsers(selected.value);
                            saveUsers(selected.value);
                        }}
                        withIcon
                    />
                    <Button secondary size={Sizes.Small} onPress={() => setShowUsersList(false)}>Done</Button>
                </div>}
            <ChipStack
                // showAdd={(selectedGroups?.length < groups?.length)}
                onAdd={() => {
                    setShowGroupsSelector(true);
                    setShowUsersList(false);
                }}
                noValueLabel={intl.formatMessage({ id: 'compliance.product_detail.responsible_users.select_group' })}
                onRemoveClip={(index) => {
                    const su = [...selectedGroups];
                    su.splice(index, 1);
                    setSelectedGroups(su);
                    saveGroups(su);
                }}
                size={Sizes.Small}
                clips={selectedGroups?.map((group) => {
                    const gs = groupsById[group];
                    if (gs) {
                        return { label: gs.name, removable: true, icon: 'user_group' }
                    }
                })} />

            {showGroupsSelector && <div className='flex w-full gap-2 pb-8'>
                <Dropdown
                    value={selectedGroups}
                    placeholder={intl.formatMessage({ id: 'groups.select_groups' })}
                    fluid
                    onChange={(e, item) => {
                        const value = item.value[(item.value as string[]).length - 1];
                        const _items = [...groupsOptions];
                        const g = [...selectedGroups, value as string]
                        setSelectedGroups(g);
                        (item?.value as string[]).forEach((_value) => {
                            groupsOptions.forEach((item, j) => {
                                if (item.id === _value) {
                                    _items.splice(j, 1);
                                }
                            })
                        })                        
                        setGroupOptions(_items);
                        saveGroups(g);
                    }}
                    
                    selection
                    multiple
                    options={groupsOptions}></Dropdown>
                <Button secondary size={Sizes.Small} onPress={() => {
                    saveGroups(selectedGroups);
                    setShowGroupsSelector(false)
                }}><FormattedMessage id='globals.done' /></Button>
            </div>
            }


        </div>

        :
        <div>xx{getLabel()}</div>}
    </div>

    return <KeyValuePair keyItem={{ name: name || intl.formatMessage({ id: 'compliance.product_detail.responsible_users' }), icon: 'user_product-responsible' }} value={{ value: content }} />
}
export default ComplianceResponsiblesSelector;
