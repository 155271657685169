import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button, { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import EmptyState, { EmptyStateType } from '../../../base/ui/components/emptyState/EmptyState';
import { OrganizedProduct } from '../../../compliance_api/models';
import ComplianceProductDetailedDeadline from './ComplianceProductDetailedDeadline';

type Props = {
    className?: string;
    showSupplierOnOrderOrDeadline: boolean;
    product: OrganizedProduct;
    onAddDueDate();
    onEditTags();
};

const ComplianceProductOrdersAndDeadlines = (props: Props): React.ReactElement => {
    const { className, product, showSupplierOnOrderOrDeadline, onAddDueDate, onEditTags } = props;
    const intl = useIntl();
    const [state, setState] = useState();
    const hasDeadline = product?.detailed_product_deadlines.length > 0;
    const hasOrders = product?.detailed_orders.length > 0;
    const hasRequirements = hasDeadline || product.product_tags.length > 0;
    const hasTags = product?.product_tags?.length > 0;
    const hasOrdersWithoutRequirements = product?.detailed_orders.length > 0 && product?.detailed_orders[0].requirements.length === 0;

    const editDueDate = true;
    const userCanEditProduct = product?.permissions.includes('product_edit');
    const dl = product.deadlines.length > 0;
    useEffect(() => {
        //
    }, []);
    return <div className={''}>
        <div className='py-2 text-2xl font-bold'><FormattedMessage id='compliance.deadline.orders_headline' /></div>
        {/* !hasRequirements && <EmptyState type={EmptyStateType.Standalone} title={intl.formatMessage({ id: 'compliance.product.activate.no_requirements_on_product' })} guidance={
            <div className='flex gap-y-6 flex-col'>
                <div>
                    <div className='font-bold'><FormattedMessage id='compliance.product.activate.no_requirements_on_product.text' /></div>
                    <div><FormattedMessage id='compliance.product.activate.no_requirements_on_product.text_2' /></div>
                </div>
                <div><Button buttonType={ButtonTypes.Primary} onPress={onEditTags}><FormattedMessage id='compliance.product.activate.no_requirements_on_product.edit_tags' /></Button>

                </div>
            </div>} /> */}
        {(!hasOrders && !hasDeadline) && <EmptyState type={EmptyStateType.Standalone} title={intl.formatMessage({ id: 'compliance.product.due_date.no_orders_yet' })} guidance={
            <div className='flex gap-y-6 flex-col'>
                <div>
                    <div className='font-bold'><FormattedMessage id='compliance.product.due_date.get_started_text' /></div>
                    <div><FormattedMessage id='compliance.product.due_date.get_started_text_2' /></div>
                </div>
                {userCanEditProduct && <div><Button buttonType={ButtonTypes.Primary} onPress={onAddDueDate}><FormattedMessage id='compliance.product.due_date.button.add_due_date' /></Button></div>}
            </div>} />}
        {!hasOrders && hasDeadline && <div className='border p-6 bg-default mb-6 rounded-md'><EmptyState type={EmptyStateType.Inline} title='No orders yet' /></div>}
        {hasOrders && product.detailed_orders?.map((dlp: any, i) => { // TODO Should use DetailedDeadline, but issue with OrderDeadlineReference getting properly selected
            return <ComplianceProductDetailedDeadline onEditTags={onEditTags} hasTags={hasTags} isOpen={i == 0} editDueDate={false} isFirst={i === 0} isLast={i === (product.detailed_orders.length - 1)} showSupplier={showSupplierOnOrderOrDeadline} productName={product.product_name} deadline={dlp} productId={product.product_id} orderId={dlp.id} key={'dl_' + dlp.deadline_name + i} />
        })}
        {hasDeadline && product.detailed_product_deadlines.map((dlp, i) => {
            return <ComplianceProductDetailedDeadline onEditTags={onEditTags} isOpen={i == 0 && !hasOrders} editDueDate={editDueDate} isFirst={i === 0} isLast={i === (product.detailed_product_deadlines.length - 1)} showSupplier={showSupplierOnOrderOrDeadline} productName={product.product_name} deadline={dlp} productId={product.product_id} key={'dl_' + dlp.name + i} />
        })}

    </div>
}
export default ComplianceProductOrdersAndDeadlines;
