import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ListItem, ListItems, SecondaryActionEnum } from '../../../../base/ui/components/listitems/ListItem';
import ListItemsHeader from '../../../../base/ui/components/listitems/ListItemsHeader';
import { showSnackbar } from '../../../../base/ui/uiSlice';
import { twMerge } from '../../../../base/utils';
import { DetailedDocumentAppliesToProduct, DetailedDocumentAppliesToSupplier, DetailedDocumentViewAppliesTo, DocumentContext } from '../../../../compliance_api/models';
import { useAppDispatch } from '../../../../store';
import { complianceAddProductsToDocument, complianceRemoveProductsFromDocument } from '../../complianceSlice';
import ComplianceTemporaryItemNumber from '../ComplianceTemporaryItemNumber';
import ComplianceDocumentSuggestProducts from './ComplianceDocumentSuggestProducts';

type Props = {
    className?: string;
    appliesTo: DetailedDocumentViewAppliesTo;
    products: DetailedDocumentAppliesToProduct[];
    suppliers: DetailedDocumentAppliesToSupplier[];
    userCanAddAndRemoveProducts: boolean;
    documentId: string;
    context: DocumentContext;
};

const ComplianceDocumentCoveredProducts = (props: Props): React.ReactElement => {
    const { className, products, suppliers, appliesTo, documentId, context, userCanAddAndRemoveProducts } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [show, setShow] = useState(false);
    const removeFromDocument = (productId: string) => {
        dispatch(complianceRemoveProductsFromDocument({ context, product_ids: [productId], document_id: documentId }))
            .then((r) => {
                if (!r.error) {
                    dispatch(showSnackbar({
                        hideAfter: 10000,
                        content: <span className='flex gap-x-1'>
                            <span><FormattedMessage id='compliance.covered_products.product_was_removed' /></span>
                        </span>,
                    }));
                }
            })
    }
    const addProductToDocument = (productIds: string[]) => {
        dispatch(complianceAddProductsToDocument({ context, document_id: documentId, product_ids: productIds }))
            .then((r) => {
                if (!r.error) {
                    dispatch(showSnackbar({
                        hideAfter: 10000,
                        content: <span className='flex gap-x-1'>
                            <span><FormattedMessage id={productIds.length > 1 ? 'compliance.covered_products.products_were_added' : 'compliance.covered_products.product_was_added'} /></span>
                        </span>,
                    }));
                }
            })
    }
    return userCanAddAndRemoveProducts && <div className={twMerge('', className)}>
        <ListItemsHeader
            title={intl.formatMessage({ id: appliesTo.type === 'applies_to_products' ? 'compliance.document_viewer.covered_products' : 'compliance.document_viewer.covered_suppliers' })}
            contextButton={!show && { label: intl.formatMessage({ id: 'compliance.covered_products.add_products' }), event: () => setShow(!show) }}
        />
        <div className={'bg-default'}>
            {products && products.length > 0 && <ListItems className={'border border-default border-b-0 rounded-md'}>
                {products.map((product) => {
                    return <ListItem className=''
                        icon={!product.image?.image ? 'object_product' : null} thumbnail={product.image?.image?.image_url}
                        meta={{ metaBelow: { firstItem: product?.item_number || product.temporary_item_number && <ComplianceTemporaryItemNumber temporaryItemNumber={product.temporary_item_number} /> } }}
                        secondaryAction={products.length > 1 && { type: SecondaryActionEnum.Remove, onSecondaryActionClick: () => removeFromDocument(product.product_id) }}
                    ><a className='hover:text-primary text-primary' target='_blank' href={'/q/app/compliance/products/' + product.product_id}>{product.name}</a></ListItem>
                })}
                {show && <ListItem>
                    <ComplianceDocumentSuggestProducts
                        currentProducts={products}
                        onItemSelected={(ids) => {
                            if (ids.length > 0) {
                                addProductToDocument(ids);
                            }
                            setShow(false);
                        }}
                        documentId={documentId}
                    />
                </ListItem>}
            </ListItems>}
            {appliesTo.type === 'applies_to_supplier' && <ListItems className={'border border-default border-b-0  rounded-md'}>
                <ListItem icon={'user_supplier'} >{appliesTo.supplier_name}</ListItem>
            </ListItems>}
        </div>
    </div>
}
export default ComplianceDocumentCoveredProducts;
