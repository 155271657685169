import { Pie } from '@nivo/pie';
import React from 'react';

type Props = {
    className?: string;
    data: { id: string, value: number, color: string }[] | {}[];
    showEmptyStatus?: boolean;
    allApproved?: boolean;
    isEmpty?: boolean;
    w?: number;
    h?: number;
};
const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (<svg className='fill-branding-brand' width={'30px'} height={'30px'} viewBox="20 20 200 1380">
        <path
            d="m0 844.3623 176 176 272-336-48-48-224 288-128-128z" />
    </svg>)
}
const StatusPie = (props: Props): React.ReactElement => {

    const { className, data, showEmptyStatus, allApproved, isEmpty, h, w } = props;
    /* const h = isEmpty ? props.h ? (props.h - 1) : 34 : props.h;
    const w = isEmpty ? props.w ? (props.w - 1) : 34 : props.w; */
    const borderWidth = isEmpty ? 1 : 0;
    return <Pie
        data={data}
        tooltip={() => null}
        isInteractive={false}
        borderColor={'#dddddd'}
        borderWidth={isEmpty ? .5 : 0}
        height={h ?? 35}
        width={w ?? 35}
        innerRadius={.5}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', !isEmpty && allApproved && CenteredMetric]}
        colors={{ datum: 'data.color' }}
    />;
}

export default StatusPie;
