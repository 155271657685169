import React from 'react';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';

export type HeaderProps = {
    className?: string;
    titleClass?: string;
    pageTitle: ComponentOrStringType;
    entity?: any;
    reference?: ComponentOrStringType;
    contextAction?: React.ReactElement;
    secondaryAction?: React.ReactElement;
    toolbar?: any;
};

const Header = (props: HeaderProps): React.ReactElement => {
    const { className, pageTitle, entity, reference, contextAction, secondaryAction, toolbar, titleClass } = props;
    const mainStyle = 'flex items-center justify-between gap-2';
    const entityStyle = 'text-brand uppercase font-bold block text-base label-heading'; // TODO: #figma-fix label-heading is not implemented yet
    const referenceStyle = twMerge('font-normal', pageTitle && 'ml-2');
    const contextActionStyle = '';
    const secondaryActionStyle = '';

    return <div className={twMerge(mainStyle, className)}>
        <h1 className='flex flex-col'>
            {entity && <span className={twMerge(entityStyle)}>{entity}</span>}
            <div className={twMerge('', titleClass)}>{pageTitle}
                {reference && <span className={twMerge(referenceStyle)}>{reference}</span>}
            </div>
        </h1>
        <div className='flex gap-2'>
            {secondaryAction && <div className={twMerge(secondaryActionStyle)}>{secondaryAction}</div>}
            {contextAction && <div className={twMerge(contextActionStyle)}>{contextAction}</div>}
        </div>
    </div>
}
export default Header;
