import React from 'react';
import ComplianceTemporaryItemNumberMarker from './ComplianceTemporaryItemNumberMarker';

type Props = {
    className?: string;
    temporaryItemNumber: string;
};

const ComplianceTemporaryItemNumber = (props: Props): React.ReactElement => {
    const { className, temporaryItemNumber } = props;

    return <><ComplianceTemporaryItemNumberMarker />{temporaryItemNumber}</>

}
export default ComplianceTemporaryItemNumber;
