import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import { useTranslation } from '../../../base/hooks';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import InfoBox from '../../../base/ui/components/infoBox/InfoBox';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { useAppDispatch } from '../../../store';
import { complianceSetProductState } from '../complianceSlice';

type Props = {
    className?: string;
    productId: string;
    handleProductUpdate(string, any): void;
    cancelCreate(): void;
};

export type ActivateProductViewType = 'noRequirementsYet';
export enum ActivateProductViewTypeEnum {
    NoRequirementsYet = 'noRequirementsYet',
}
const ComplianceActivateProductWizard = (props: Props): React.ReactElement => {
    const { className, productId } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const modalData = useSelector(getShowModalSelector)['dueDateWizard'];
    const [view, setView] = useState<ActivateProductViewType>(modalData?.data?.view as ActivateProductViewTypeEnum);

    const save = () => {
        if (view === ActivateProductViewTypeEnum.NoRequirementsYet) {
            dispatch(complianceSetProductState({ product_id: productId, state: 'active' }));
            hide();
        }
    }
    const hide = () => {
        dispatch(showModal({ id: 'dueDateWizard', show: false }));
    }
    const dontSet = () => {
        hide();

    }
    const headerMap = {
        noRequirementsYet: intl.formatMessage({ id: 'compliance.product.activate.header.no_tags_yet' }),
    }
    const actionsMap = {
        noRequirementsYet: {
            ok: intl.formatMessage({ id: 'compliance.product.activate.activate_now' }),
            dont: intl.formatMessage({ id: 'globals.cancel' }),
        },
    }
    const contentMap = {
        noRequirementsYet: <div className='gap-y-6 flex flex-col'>
            <div>
                {useTranslation('compliance.product.activate.header.no_tags_yet.text')}
            </div>
            <InfoBox
                header={useTranslation('compliance.product.activate.header.no_tags_yet.note_text.note')}
                message={useTranslation('compliance.product.activate.header.no_tags_yet.note_text')} />            
        </div>,
    }

    useEffect(() => {
        if (modalData) {
            setView(modalData?.data?.view);
        }
    }, [modalData]);

    return <Modal
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: actionsMap[view]?.ok, event: save, stayOpen: true },
            { type: ActionTypes.Action, buttonType: ButtonTypes.Plain, text: actionsMap[view]?.dont, event: dontSet },
        ]}
        isOpen={modalData?.show}
        width={500}
        header={headerMap[view]}
        content={contentMap[view]}
        onClose={hide}

    />;
}
export default ComplianceActivateProductWizard;
