import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useTranslation } from '../../../base/hooks';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import { Field } from '../../../base/ui/components/fields/Field';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';

type Props = {
    className?: string;
    tempItemNumber: string;
    show: boolean;
    handleReplace(string): void;
    onClose(): void;
};

const ComplianceTempItemModal = (props: Props): React.ReactElement => {
    const { className, handleReplace, onClose, tempItemNumber } = props;
    const intl = useIntl();
    const [itemNumber, setItemNumber] = useState(null);
    const [show, setShow] = useState(props.show);
    useEffect(() => {
        setShow(props.show);
    }, [props.show])
    const [errorMessage, setErrorMessage] = useState({ number: null });
    const checkValidation = () => {
        const numberError = intl.formatMessage({ id: 'compliance.product_create.error.please_set_product_number' });
        const numberValid = !isUndefinedOrNullOrEmptyString(itemNumber);
        setErrorMessage({
            ...errorMessage,
            number: numberValid ? null : numberError,
        })
        return numberValid;
    }
    const save = () => {
        if (checkValidation()) {
            props.handleReplace(itemNumber);
            setShow(false);
        }
    }
    const cancel = () => {
        setShow(false);
        onClose?.();
    }
    const createModal = <Modal
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: intl.formatMessage({ id: 'compliance.product_temp_item.replace_number' }), event: save, stayOpen: true /* disabled: isUndefinedOrNullOrEmptyString(productName) || isUndefinedOrNullOrEmptyString(productNumber) */ },
            { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'globals.cancel' }), event: cancel },
        ]}
        onCancelAction={close}
        onClose={() => {
            cancel();
        }}
        isOpen={show}
        width={500}
        header={useTranslation('compliance.product_temp_item.replace_number.header')}
    >
        <div className='flex flex-col gap-y-4'>
            <div>{useTranslation('compliance.product_temp_item.replace_number_text', { number: tempItemNumber })}</div>
            <Field
                type='text'
                label={{ text: useTranslation('compliance.product_temp_item.item_number') }}
                value={itemNumber}
                onChange={setItemNumber}
                errorMessage={errorMessage.number}
                autoFocus={true}
            />
        </div>
    </Modal >

    return createModal;
}
export default ComplianceTempItemModal;
